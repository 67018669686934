<template>
  <div>
    <el-breadcrumb class="breadcrumb" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/profile/third_party' }">{{
        $t("personal.thirdPartyManagement.breadcrumb.app")
      }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{ detail.name }}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="card">
      <el-row type="flex" class="card-top" style="position: relative">
        <div
          class="card-top-des-tag"
          style="position: absolute; top: 90px; left: 17.5%"
        >
          {{ $t("personal.thirdPartyManagement.free") }}
        </div>
        <el-col :span="4">
          <el-avatar
            style="background: transparent; width: fit-content"
            shape="square"
            :size="100"
            :src="detail.avatar"
          ></el-avatar>
        </el-col>
        <el-col :span="16">
          <el-row type="flex" class="card-top-name">{{ detail.name }}</el-row>
          <el-row type="flex" class="card-top-des">
            <el-col class="card-top-des-content" :span="22"
              ><a target="_blank" :href="detail.homeLink">{{
                detail.homeLink
              }}</a></el-col
            >
          </el-row>
        </el-col>
        <el-col :span="4">
          <el-button
            v-if="!isConfig"
            size="mini"
            class="card-top-btn"
            @click="get_app()"
            >{{ $t("personal.thirdPartyManagement.get") }}</el-button
          >
          <el-button
            v-if="isConfig"
            class="card-top-btn"
            @click="openConfig()"
            >{{ $t("personal.thirdPartyManagement.config") }}</el-button
          >
        </el-col>
      </el-row>
      <el-tabs v-model="activeName" class="card-middle">
        <el-tab-pane
          :label="$t('personal.thirdPartyManagement.detail.tab')"
          name="first"
        >
          <el-row class="card-middle-content">
            <span style="font-weight: 800; margin-right: 15px; color: #333"
              >{{ $t("personal.thirdPartyManagement.detail.intro") }}:
            </span>
            <span v-html="descriptionLanguage(detail.description)"></span>
          </el-row>
          <el-row class="card-middle-content">
            <span style="font-weight: 800; margin-right: 15px; color: #333"
              >{{ $t("certificate.Preview") }}:
            </span>
          </el-row>
          <el-row class="card-middle-swipper">
            <el-carousel
              width="800px"
              height="450px"
              type="card"
              :autoplay="false"
              style="margin-top: 20px"
            >
              <el-carousel-item
                v-for="(item, index) in detail.pictures"
                :key="item.name"
                class="card-middle-swipper-item"
              >
                <el-image
                  fit="fit"
                  :src="item.pictureUrl"
                  :preview-src-list="getPreviewImgList(index)"
                />
              </el-carousel-item>
            </el-carousel>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </el-card>
    <el-dialog
      :title="$t('personal.thirdPartyManagement.get') + detail.name"
      width="25%"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :show-close="true"
    >
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button type="primary" @click="onOK()">{{
          $t("personal.thirdPartyManagement.add")
        }}</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :title="detail.name + $t('personal.thirdPartyManagement.config')"
      width="25%"
      :visible.sync="firstDialogVisible"
      :close-on-click-modal="false"
      :show-close="true"
    >
      <p style="margin-bottom: 30px">
        <span>{{ $t("Jira.desBefore") }}</span>
        <a :href="jiraURL" target="blank">{{ $t("Jira.desMiddle") }}</a>
        <span>{{ $t("Jira.desAfter") }}</span>
      </p>
    </el-dialog>

    <el-dialog
      :title="detail.name + $t('personal.thirdPartyManagement.config')"
      width="25%"
      :visible.sync="configDialogVisible"
      :close-on-click-modal="false"
      :show-close="true"
    >
      <p style="margin-bottom: 30px">
        <span>{{ $t("Jira.redesBefore") }}</span>
        <a :href="jiraURL" target="blank">{{ $t("Jira.redesMiddle") }}</a>
        <span>{{ $t("Jira.redesAfter") }}</span>
      </p>
    </el-dialog>

    <el-dialog
      :title="detail.name + $t('personal.thirdPartyManagement.config')"
      width="25%"
      :visible.sync="jiraChange"
      :close-on-click-modal="false"
      :show-close="true"
    >
      <p style="margin-bottom: 30px">
        <span>{{ $t("Jira.compDes") }}</span>
      </p>
    </el-dialog>

    <thirdPartyConfigDialog
      :dialog_visible.sync="isShowconfigDialog"
      :title_text="detail.name + $t('personal.thirdPartyManagement.config')"
      :tip_text="$t('thirdPartyDetail.des')"
    ></thirdPartyConfigDialog>

    <el-dialog
      :title="detail.name + $t('personal.thirdPartyManagement.config')"
      :visible="isShowGerritDialog"
      :close-on-click-modal="false"
      :show-close="true"
      width="25%"
      @close="isShowGerritDialog = false"
    >
      <p style="margin-bottom: 30px; font-size: 16px">
        <span
          >{{ $t("thirdPartyDetail.click") }}
          <a
            href="https://ms.ytdevops.com/pubKnowledge/3c356b8f-19ca-4e7a-a234-604f41e2dff4"
            target="_blank"
            >{{ $t("thirdPartyDetail.this") }}</a
          >
          {{ $t("thirdPartyDetail.config") }}</span
        >
      </p>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      :show-close="true"
      :visible.sync="authentication_dialog"
      width="50%"
    >
      <div class="authentication_wrap">
        <div>
          <el-alert
            :title="$t('personal.ones.title')"
            type="warning"
            :closable="false"
            show-icon
          >
          </el-alert>
        </div>
        <div class="authentication_title">
          <span class="index_class">1</span> {{ $t("personal.ones.subtitle1") }}
        </div>
        <div class="authentication_des">
          <p>
            1. {{ $t("personal.ones.step1before")
            }}<a
              href="https://developer.ones.com/zh-CN/docs/openapi/authorization#%E5%88%9B%E5%BB%BA-oauth-%E5%BA%94%E7%94%A8"
              target="_blank"
              >{{ $t("personal.ones.step1middle") }}</a
            >{{ $t("personal.ones.step1after") }}
          </p>
          <p>2. {{ $t("personal.ones.step2") }}</p>
          <div class="authentication_title">
            {{ $t("projectDetails.addGitlabForm.addAutoForm.step1.url") }}
          </div>
          <div>
            <span ref="redirect_url" class="authentication_code_area">
              {{ default_url }}</span
            >
            <el-button size="small" style="float: right" @click="copy_url">{{
              $t("btn.copyBtn")
            }}</el-button>
          </div>
          <div class="authentication_title">
            {{ $t("personal.ones.authenticationType") }}
          </div>
          <div>
            <span class="authentication_code_area">{{
              $t("personal.ones.organization")
            }}</span>
          </div>
          <div class="authentication_title">
            {{ $t("personal.ones.Scope") }}
          </div>
          <div>
            <span class="authentication_code_area">{{
              $t("personal.ones.projectManagement")
            }}</span>
          </div>
        </div>
        <div class="authentication_title">
          <span class="index_class">2</span>{{ $t("personal.ones.subtitle2") }}
        </div>
        <div class="authentication_des">
          <p>{{ $t("personal.ones.step3") }}</p>
          <div
            style="
              font-size: 16px;
              font-weight: 700;
              color: #000;
              margin: 15px 0 10px 0;
            "
          >
            <span style="color: red">&nbsp;*</span>Client id
          </div>
          <div>
            <el-input
              v-model="authentication_form.clientId"
              :placeholder="$t('personal.ones.pl1')"
            ></el-input>
          </div>
          <div
            style="
              font-size: 16px;
              font-weight: 700;
              color: #000;
              margin: 15px 0 10px 0;
            "
          >
            <span style="color: red">&nbsp;*</span>Client Secret
          </div>
          <div>
            <el-input
              v-model="authentication_form.clientSecret"
              :placeholder="$t('personal.ones.pl2')"
            ></el-input>
          </div>
          <div
            style="
              font-size: 16px;
              font-weight: 700;
              color: #000;
              margin: 15px 0 10px 0;
            "
          >
            <span style="color: red">&nbsp;*</span>URL
          </div>
          <div>
            <el-select
              v-model="protocol"
              placeholder="请选择"
              style="width: 20%"
            >
              <el-option
                v-for="item in protocolOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-input
              v-model="authentication_form.onesUrl"
              :placeholder="$t('personal.ones.pl3')"
              style="width: 80%"
            ></el-input>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="authentication_dialog_confrim_cancel">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button type="primary" @click="authentication_dialog_confrim">{{
          $t("btn.newBtn")
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="detail.name + $t('personal.thirdPartyManagement.config')"
      width="25%"
      :visible.sync="hasOnes_dialog"
      :close-on-click-modal="false"
      :show-close="true"
    >
      <p style="margin-bottom: 30px">
        <span>{{ $t("Ones.desBefore") }}</span>
        <a href="#" @click="authentication_dialog = true">{{
          $t("Ones.desMiddle")
        }}</a>
      </p>
    </el-dialog>
    <label style="display: block">
      <textarea ref="text_area" style="opacity: 0"></textarea>
    </label>
    <ContactPhoneDialog
      :dialogVisible="dialogVisiblePhone"
      @cancelDialog="cancelDialogPhone"
    ></ContactPhoneDialog>
  </div>
</template>
<script>
import con from "@/../config/index";
import {
  get_third_app_detail_by_id,
  get_app_by_id,
} from "@/network/third-party/index";
import { initConfig, getJiraConfig } from "@/network/jira/index";
import thirdPartyConfigDialog from "./components/thirdPartyConfigDialog.vue";
import { saveOnesConfigs, getOnesConfigs } from "@/network/gitlab";
import { getPhoneInfo } from "@/network/login";
import ContactPhoneDialog from "@/components/contactPhoneDialog";
export default {
  components: {
    thirdPartyConfigDialog,
    ContactPhoneDialog,
  },
  data() {
    return {
      activeName: "first",
      detail: {},
      dialogVisible: false,
      firstDialogVisible: false,
      configDialogVisible: false,
      jiraChange: false,
      isShowconfigDialog: false,
      role: "",
      isShowGerritDialog: false,
      authentication_dialog: false,
      hasOnes_dialog: false,
      protocolOptions: [
        {
          label: "https://",
          value: "https://",
        },
        {
          label: "http://",
          value: "http://",
        },
      ],
      authentication_form: {
        clientId: "",
        clientSecret: "",
        onesUrl: "",
      },
      protocol: "https://",
      // 是否显示添加手机号
      dialogVisiblePhone: false,
    };
  },
  computed: {
    jiraURL() {
      return con.jira_url;
    },
    applicationId() {
      return this.$route.params.applicationId;
    },
    obtained_list() {
      return this.$store.getters.project_third_apps;
    },
    isConfig() {
      let flag = this.$store.getters.project_third_apps.findIndex(
        (item) => item.applicationId == this.applicationId
      );
      if (flag != -1) {
        return true;
      } else {
        return false;
      }
    },
    default_url() {
      return con.authentication_url + "ones/authentication";
    },
  },
  beforeDestroy() {
    clearInterval(this.validation_ones_interval);
  },
  mounted() {
    this.get_third_app_detail_by_id();
    this.role = JSON.parse(localStorage.getItem("store")).user.roles
      ? JSON.parse(localStorage.getItem("store")).user.roles[0].name
      : "NORMAL_USER";
  },
  methods: {
    // 取消手机号弹窗
    cancelDialogPhone(value) {
      if (value === "update") {
        this.dialogVisible = true;
      }
      this.dialogVisiblePhone = false;
    },
    // 描述区分语言类型
    descriptionLanguage(des) {
      if (des) {
        return des[localStorage.getItem("lang") || "zh"];
      }
    },
    getPreviewImgList(index) {
      let arr = [];
      for (let i = 0; i < this.detail.pictures.length; i++) {
        arr.push(this.detail.pictures[i + index].pictureUrl);
        if (i + index >= this.detail.pictures.length - 1) {
          // 为了取后边小值（采用绝对值）
          index = 0 - (i + 1);
        }
      }
      return arr;
    },
    // 安装
    onOK() {
      // this.isConfig = true;
      this.dialogVisible = false;
      get_app_by_id(this.applicationId)
        .then(() => {
          this.$store.commit("SET_THIRD_APPS", [
            ...this.obtained_list,
            {
              applicationId: this.applicationId,
              avatar: this.detail.avatar,
              description: this.detail.description,
              name: this.detail.name,
            },
          ]);
          this.$message({
            type: "success",
            message: this.$t("user.userTable.message.addSuccess"),
          });
          if (this.applicationId == "eee0d627-5547-4811-af5c-dcdf4e19cda0") {
            getJiraConfig()
              .then((res) => {
                if (res !== null && res.length !== 0) {
                  localStorage.setItem("hasJiraConfig", true);
                } else {
                  localStorage.setItem("hasJiraConfig", false);
                }
              })
              .catch((e) => {
                console.log(e);
              });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // 获取应用详情
    get_third_app_detail_by_id() {
      get_third_app_detail_by_id(this.applicationId).then((res) => {
        this.detail = { ...res };
        this.$forceUpdate();
      });
    },
    // 跳转到配置页面
    go_to_config(event, item) {
      this.$router.push({
        path: "/profile/third_party/config",
        params: { item },
      });
      event.stopPropagation();
    },
    // 点击配置
    openConfig() {
      if (this.role === "SYSTEM_MANAGER") {
        // 如果是jira
        if (this.applicationId === "eee0d627-5547-4811-af5c-dcdf4e19cda0") {
          localStorage.setItem("jiraChange", false);
          getJiraConfig()
            .then((res) => {
              if (res !== null && res.length !== 0) {
                localStorage.setItem("hasJiraConfig", true);
                this.configDialogVisible = true;
              } else {
                localStorage.setItem("hasJiraConfig", false);
                this.firstDialogVisible = true;
              }
            })
            .catch((err) => {
              console.log(err);
            });
          // 轮询监听
          let timer = window.setInterval(() => {
            setTimeout(() => {
              let localJira = localStorage.getItem("jiraChange");
              if (localJira === "true") {
                localStorage.setItem("hasJiraConfig", true);
                this.firstDialogVisible = false;
                this.configDialogVisible = false;
                this.jiraChange = true;
                clearInterval(timer);
              }
            }, 0);
          }, 1000);
        } else {
          if (this.detail.name === "Gerrit") {
            this.isShowGerritDialog = true;
          } else {
            if (this.detail.name === "Ones") {
              getOnesConfigs().then((res) => {
                if (res.result) {
                  this.hasOnes_dialog = true;
                  this.authentication_form = res.onesConfigDto;
                  if (res.onesConfigDto.onesUrl.indexOf("https://") !== -1) {
                    this.protocol = "https://";
                    const regExp = new RegExp("https://", "g");
                    this.authentication_form.onesUrl =
                      this.authentication_form.onesUrl.replace(regExp, "");
                  } else if (
                    res.onesConfigDto.onesUrl.indexOf("http://") !== -1
                  ) {
                    this.protocol = "http://";
                    const regExp = new RegExp("http://", "g");
                    this.authentication_form.onesUrl =
                      this.authentication_form.onesUrl.replace(regExp, "");
                  } else {
                    this.protocol = "https://";
                  }
                } else {
                  this.authentication_dialog = true;
                }
              });
            } else {
              this.isShowconfigDialog = true;
            }
          }
        }
      } else {
        this.$message.error(this.$t("thirdPartyDetail.noAccess"));
      }
    },
    get_app() {
      if (this.role === "SYSTEM_MANAGER") {
        getPhoneInfo().then((res) => {
          if (res.contactPhone) {
            this.dialogVisible = true;
          } else {
            this.dialogVisiblePhone = true;
          }
        });
      } else {
        this.$message.error(this.$t("thirdPartyDetail.noAccess"));
      }
    },
    authentication_dialog_confrim() {
      if (
        !this.authentication_form.clientId.length ||
        !this.authentication_form.clientSecret.length ||
        !this.authentication_form.onesUrl.length
      ) {
        this.$message({
          type: "warning",
          message: this.$t("projectDetails.message.warn2"),
        });
      } else {
        this.authentication_form.onesUrl[
          this.authentication_form.onesUrl.length - 1
        ] === "/"
          ? (this.authentication_form.onesUrl =
              this.authentication_form.onesUrl.substring(
                0,
                this.authentication_form.onesUrl.length - 1
              ))
          : "";
        const params = {
          projectId: this.get_pid(),
          data: {
            clientId: this.authentication_form.clientId,
            clientSecret: this.authentication_form.clientSecret,
            onesUrl: this.protocol + this.authentication_form.onesUrl,
          },
        };
        saveOnesConfigs(params).then((res) => {
          const href = `${
            this.protocol + this.authentication_form.onesUrl
          }/oauth2/authorize?client_id=${
            this.authentication_form.clientId
          }&response_type=code`;
          window.open(href, "_blank");
          this.authentication_dialog = false;
          this.hasOnes_dialog = false;
        });
      }
    },
    copy_url() {
      let text1 = this.$refs["redirect_url"];
      let text2 = this.$refs["text_area"];
      text2.innerText = text1.innerText;
      text2.select();
      document.execCommand("copy");
      this.$message({
        message: this.$t("projectDetails.copyed"),
        type: "success",
      });
    },
    authentication_dialog_confrim_cancel() {
      this.authentication_dialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.authentication_wrap {
  text-align: left;
  .index_class {
    display: inline-block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    background: rgba(48, 100, 143, 1);
    color: #fff;
    border-radius: 50%;
    margin-right: 15px;
  }
  .authentication_title {
    color: #000;
    margin: 10px 0 8px 0;
  }
  .authentication_des {
    padding-left: 30px;
    .authentication_code_area {
      display: inline-block;
      margin-right: 12px;
      height: 33px;
      line-height: 33px;
      background: rgba(235, 235, 235, 1);
      padding: 0 12px;
      border-radius: 4px;
    }
  }
}
.breadcrumb {
  margin-bottom: 2vh;
  font-size: 18px;
}
.card {
  height: 85vh;
  width: 65vw;
  overflow: scroll;
  border-radius: 18px;
  &-top {
    &-name {
      font-size: 18px;
      font-weight: bold;
      margin: 10px;
    }
    &-des {
      justify-content: space-between;
      align-items: center;
      margin: 10px;

      &-content {
        display: flex;
        justify-content: flex-start;
        font-size: 16px;
        a {
          color: rgba(112, 112, 112, 1);
        }
        color: rgba(112, 112, 112, 1);
      }
      &-tag {
        display: flex;
        justify-content: center;
        border-radius: 10px;
        background: rgba(48, 100, 143, 1);
        color: white;
        width: 50px;
        height: 20px;
      }
    }
    &-version {
      margin: 10px;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: rgba(112, 112, 112, 1);

      &-number {
        display: flex;
        justify-content: flex-start;
      }
      &-name {
        display: flex;
        justify-content: flex-start;
      }
    }
    &-btn {
      background: rgba(48, 100, 143, 1);
      color: white;
      font-size: 12px;
    }
  }
  &-middle {
    justify-content: center;
    font-size: 16px;
    color: rgba(112, 112, 112, 1);
    &-content {
      text-align: left;
      margin: 30px;
    }
    &-swipper {
      margin: 30px;
      text-align: left;
      &-item {
        width: 800px;
        height: 450px;
        background-size: cover;
      }
    }
  }

  .el-carousel__item:nth-child(2n) {
    // background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n + 1) {
    // background-color: #d3dce6;
  }
}
</style>
