<template>
  <el-dialog
    :title="title_text"
    :close-on-click-modal="false"
    :show-close="true"
    :visible="dialog_visible"
    width="25%"
    @close="closeDialog"
  >
    <p style="margin-bottom: 30px; font-size: 17px">
      <span>{{ tip_text }}</span>
    </p>
  </el-dialog>
</template>

<script>
export default {
  name: "ThirdPartyConfigDialog",
  props: {
    // 需传入三个参数
    // 1.弹窗是否显示
    dialog_visible: {
      type: Boolean,
      default: false,
    },
    // 2.标题文字
    title_text: {
      type: String,
      default: "",
    },
    // 3.提示文字
    tip_text: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  methods: {
    closeDialog() {
      this.$emit("update:dialog_visible", false);
    },
  },
};
</script>

<style lang="scss"></style>
